@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i&display=swap&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese");

body {
  font-size: 12px;
  font-family: "Open Sans", "Arial", sans-serif;
  line-height: 20px;
  color: #000000;
}

header {
  margin-bottom: 20px;
  text-align: center;
}

h2 {
  background: #000;
  color: #fff;
  padding: 2px 8px;
  font-size: 12px;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex>div,
.flex>p,
.flex>label,
.flex>span {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
}

.flex>div,
.flex>p {
  margin: 0 20px auto 0 !important;
}

.flex>div:last-child,
.flex>p:last-child {
  margin-right: 0 !important;
}

.fields {
  margin-bottom: 15px;
}

.fields>div,
.fields>p {
  margin: 0 0 auto 0;
}

.fields>div>label,
.fields>p>label {
  font-size: 11px;
  line-height: 20px;
  font-weight: bold;
  padding: 0 5px 0 0;
}

.fields>div>span.value,
.fields>p>span.value {
  font-size: 12px;
  line-height: 20px;
}

.dotted {
  background: url("http://clients.e-lixir.fr/howdens/dots.png") 0 0 repeat-x;
}

.dotted>label {
  display: inline-block;
  background: #fff;
}

.italic {
  font-style: italic;
}

span.checkbox {
  display: inline-block;
  width: 8px;
  height: 8px;
  border: 1px solid #000;
  border-top-width: 2px;
  border-right-width: 2px;
  margin: 5px 5px 0 0;
  vertical-align: top;
}

span.checkbox.checked {
  background: #000;
}

@font-face {
  font-family: "Rooster";
  src: url("./assets/fonts/Rooster-Regular.woff") format("woff");
}

@font-face {
  font-family: "Rooster";
  font-style: italic;
  src: url("./assets/fonts/Rooster-Italic.woff") format("woff");
}

@font-face {
  font-family: "Rooster";
  font-weight: bold;
  src: url("./assets/fonts/Rooster-Black.woff") format("woff");
}

@font-face {
  font-family: "Rooster";
  font-weight: 800;
  src: url("./assets/fonts/Rooster-CondensedBold.woff") format("woff");
}

/* html5doctor.com Reset v1.6.1 (http://html5doctor.com/html-5-reset-stylesheet/) - http://cssreset.com */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-search--inline {
  float: left;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
  padding: 1px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results>.select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[role="group"] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled="true"] {
  color: #999;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #ddd;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results>.select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option[role="group"] {
  padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled="true"] {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

header {
  margin: 10px 0 0;
}

header .wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

header #logo {
  display: block;
  width: 170px;
  height: 100px;
  margin: 0 auto 0 0;
  background: url(./assets/img/ui/logo1.png) no-repeat left center;
  background-size: 100%;
}

header nav {
  font-size: 15px;
}

header nav a {
  margin: 0 10px;
  padding-bottom: 3px;
  color: #bcbcbc;
  border-bottom: 2px solid transparent;
}

header nav a:hover,
header nav a:active,
header nav a:focus,
header nav a.actif {
  color: #d22630;
  border-color: #d22630;
}

header nav a:last-of-type {
  margin-right: 0;
}

@media only screen and (max-width: 767px) {
  header {
    margin-top: 10px;
  }

  header nav {
    display: none;
  }
}

footer {
  color: #fff;
  background: black;
}

footer .wrap {
  padding: 75px 0;
  display: flex;
  justify-content: flex-start;
  background: url(./assets/img/ui/logo-footer.png) no-repeat right center;
  background-size: 210px 90px;
}

footer nav {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 50px 0 0;
  font-size: 14px;
  line-height: 15px;
}

footer nav p {
  margin: 0 0 15px;
  font-weight: bold;
}

footer nav a {
  margin: 0 0 15px;
  font-size: 12px;
  color: #fff;
}

footer nav a:hover,
footer nav a:active,
footer nav a:focus {
  color: #d22630;
}

@media only screen and (max-width: 1023px) {
  footer {
    padding: 40px 0;
  }

  footer .wrap {
    justify-content: space-between;
    padding: 0 0 110px;
    background: url(./assets/img/ui/logo-footer-responsive.png) no-repeat center bottom;
    background-size: 200px 90px;
  }

  footer nav {
    margin: 0 10px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  footer .wrap {
    flex-direction: column;
  }

  footer nav {
    margin: 0 0 10px 0;
    text-align: center;
  }

  footer nav p {
    margin: 0 0 5px;
  }

  footer nav a {
    margin: 0 0 5px;
  }
}

h1 {
  font-size: 40px;
  line-height: 40px;
  text-transform: uppercase;
  margin: 0 0 40px;
}

p {
  color: #707070;
  margin: 0 0 15px;
}

.cta {
  display: inline-block;
  margin: 0 auto;
  padding: 7px 35px 10px;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  transition: all 150ms ease-in-out;
  border: 1px solid #d22630;
  text-transform: uppercase;
  color: white;
  border-radius: 25px;
  background: #d22630;
}

.cta:hover,
.cta:active,
.cta:focus {
  color: #d22630;
  background: white;
  border-radius: 25px;
}

@media only screen and (max-width: 767px) {
  .cta {
    font-size: 16px;
    line-height: 22px;
  }
}

#cookies {
  padding: 5px 0px;
  font-size: 12px;
  color: #fff;
  background: #777;
}

#cookies .wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

#cookies a {
  text-decoration: underline;
  color: #fff;
}

#cookies a:hover,
#cookies a:active,
#cookies a:focus {
  color: #444;
}

#cookies button {
  margin: 5px 0;
  padding: 2px 10px 4px;
  font-size: 12px;
  font-family: "Rooster", "Arial", sans-serif;
  font-weight: bold;
  cursor: pointer;
  color: #777;
  border: none;
  border-radius: 10px;
  background: #fff;
}

#cookies button:hover,
#cookies button:active,
#cookies button:focus {
  color: #fff;
  background-color: #444;
}

@media only screen and (max-width: 1023px) {
  #cookies {
    padding: 10px 0px;
  }
}

@media only screen and (max-width: 767px) {
  #cookies {
    text-align: center;
  }

  #cookies .wrap {
    flex-direction: column;
  }
}

#home {
  margin: 30px 0 80px;
}

#home p {
  color: black;
  margin: 0 0 15px;
}

#home #banner {
  width: 100%;
  height: 300px;
  background: url("./assets/img/home.jpg") no-repeat center center;
  background-size: cover;
  display: flex;
  margin: 0 0 15px;
}

#home #banner>div {
  margin: auto;
  text-align: center;
}

#home #banner>div h1 {
  font-size: 40px;
  line-height: 40px;
  text-transform: uppercase;
  color: #fff;
  margin: 0 0 15px;
}

#home #banner>div a {
  margin: 0 auto;
  font-size: 20px;
  line-height: 27px;
}

#home #banner>div a:hover,
#home #banner>div a:active,
#home #banner>div a:focus {
  border-color: white;
}

#home #subscribe {
  font-size: 18px;
  background: url(./assets/img/ui/arrow_down.png) no-repeat center 90%;
  background-size: 37px 22px;
}

#home #subscribe p {
  max-width: 740px;
  margin: 0 auto 20px;
  padding: 15px 0 50px 175px;
  font-size: 30px;
  line-height: 38px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  background: url(./assets/img/ui/bg-subscribe.png) no-repeat left 5px;
  background-size: 162px 170px;
}

#home #subscribe p strong {
  font-weight: bold;
}

#home #home_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 20px;
}

#home #home_content h2 {
  color: #d22630;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 5px;
}

#home #home_content p {
  font-weight: normal;
  color: #707070;
  font-size: 14px;
  line-height: 18px;
  position: relative;
}

#home #home_content p:after {
  content: "";
  width: 62px;
  height: 1px;
  background-color: #d22630;
  display: block;
  margin-top: 5px;
}

#home #home_content img {
  margin: 0 0 10px;
}

#home #home_content>div>div {
  padding: 0 0 0 105px;
  background-repeat: no-repeat;
  background-position: left center;
  margin: 0 0 40px;
}

#home #home_content>div>div.storage {
  background-image: url("./assets/img/ui/storage.png");
  background-size: 85px 85px;
}

#home #home_content>div>div.plan_3d {
  background-image: url("./assets/img/ui/plan_3d.png");
  background-size: 85px 73px;
}

#home #home_content>div>div.showroom {
  background-image: url("./assets/img/ui/showroom.png");
  background-size: 78px 74px;
}

#home #home_content>div>div.caisson {
  background-image: url("./assets/img/ui/caisson.png");
  background-size: 69px 99px;
}

#home #home_content>div>div.sub {
  padding: 0;
  display: flex;
  justify-content: center;
}

#home #find_depot {
  text-align: center;
}

#home #find_depot h3 {
  text-transform: uppercase;
  color: #d22630;
  letter-spacing: 2px;
  font-size: 25px;
  line-height: 33px;
  margin: 0 0 20px;
  font-weight: normal;
  position: relative;
}

#home #find_depot h3:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 52%;
  width: 100%;
  height: 1px;
  background: #e6e7e8;
}

#home #find_depot h3 span {
  background-color: white;
  padding: 0 10px;
  position: relative;
  display: inline-block;
  z-index: 2;
}

#home #find_depot p {
  margin: 0 0 20px;
  color: #707070;
  font-size: 14px;
  line-height: 18px;
}

@media only screen and (max-width: 1023px) {
  #home {
    margin: 50px 0;
  }

  #home #banner>div h1 {
    font-size: 30px;
    line-height: 30px;
  }

  #home #banner>div a {
    font-size: 18px;
    line-height: 25px;
  }

  #home #subscribe {
    margin: 0;
    padding: 0;
  }

  #home #subscribe p {
    padding: 15px 0 70px 175px;
    font-size: 25px;
    line-height: 32px;
  }

  #home #home_content {
    justify-content: center;
  }

  #home #home_content>div:first-of-type,
  #home #home_content>div:last-of-type {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  #home {
    margin: 20px 0 60px;
  }

  #home #banner>div h1 {
    font-size: 25px;
    line-height: 25px;
  }

  #home #banner>div a {
    font-size: 16px;
    line-height: 22px;
  }

  #home #subscribe p {
    max-width: 460px;
    padding: 5px 0 70px 95px;
    font-size: 16px;
    line-height: 20px;
    background-size: 80px 82px;
  }

  #home #find_depot h3 {
    font-size: 20px;
    line-height: 22px;
    margin: 0 0 15px;
  }
}

#inscription {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-content: space-between;
  margin: 30px 0;
}

#inscription h1,
#inscription h2 {
  width: 100%;
  margin: 20px 0 20px 0;
  padding-bottom: 2px;
  font-size: 18px;
  text-transform: uppercase;
  color: #d22630;
  border-bottom: 1px solid #d22630;
}

#inscription .field {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 45%;
  margin: 0 0 20px;
  flex: 1 0 100%;
}

#inscription .field.disabled {
  cursor: default;
  opacity: 0.3;
}

#inscription .field.disabled * {
  cursor: default;
}

#inscription .field.disabled input:focus,
#inscription .field.disabled span.file:focus {
  border: 1px solid #c8c8c8;
}

#inscription .field .double {
  display: flex;
  flex-wrap: nowrap;
}

#inscription .field .double input,
#inscription .field .double select {
  flex-grow: 1;
  flex-shrink: 1;
}

#inscription .field .double input#indicator,
#inscription .field .double select#indicator {
  max-width: 80px;
  padding: 0 10px;
  margin: 0 15px 0 0;
}

#inscription .field[data-country="BE"] {
  display: none;
}

#inscription .full {
  max-width: 100%;
}

#inscription .chk {
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
}

#inscription .chk input {
  margin: 6px 10px 0 0;
  padding: 0;
  border: none;
  background: none;
}

#inscription .chk label {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
}

#inscription label {
  margin: 0 0 5px;
  font-size: 18px;
  cursor: pointer;
  color: #000;
}

#inscription input,
#inscription select,
#inscription textarea,
#inscription span.file {
  overflow: hidden;
  box-sizing: border-box;
  max-width: 100%;
  padding: 15px 0;
  font-size: 16px;
  font-family: "Rooster", "Arial", sans-serif;
  text-indent: 10px;
  border: 1px solid #c8c8c8;
  background: #f9f9f9;
}

#inscription input.error,
#inscription select.error,
#inscription textarea.error,
#inscription span.file.error {
  border-left: 2px solid #c20016;
}

#inscription input:active,
#inscription input:focus,
#inscription select:active,
#inscription select:focus,
#inscription textarea:active,
#inscription textarea:focus,
#inscription span.file:active,
#inscription span.file:focus {
  border-color: #777;
}

#inscription input[type="file"] {
  display: none;
}

#inscription span.file {
  overflow: hidden;
  padding-right: 15px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#inscription select {
  padding: 15px 15px 15px 0;
  text-indent: 5px;
}

#inscription .g-recaptcha.error {
  border-left: 2px solid #c20016;
}

#inscription .cta {
  display: inline-block;
  padding: 19px 30px 20px;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  cursor: pointer;
  text-indent: 0;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #28343d;
  border-radius: 0;
  background: #28343d;
}

#inscription .cta:hover,
#inscription .cta:focus,
#inscription .cta:active {
  color: #fff;
  border-color: #d22630;
  background: #d22630;
}

#inscription .cta:disabled {
  color: #c8c8c8;
  background: #fff;
}

#inscription .errors {
  display: none;
  padding: 0 0 5px 15px;
  font-size: 16px;
  color: #c20016;
  border-left: 2px solid #c20016;
}

#inscription #sepa_fields {
  display: flex;
  visibility: hidden;
  flex-wrap: wrap;
  justify-content: center;
  justify-content: space-between;
  height: 0;
}

#inscription #sepa_fields.visible {
  visibility: visible;
  height: auto;
}

#inscription #sepa_fields small {
  display: inline-block;
  font-size: 13px;
  font-style: italic;
  line-height: 20px;
}

#inscription span.select2 {
  overflow: hidden;
  box-sizing: border-box;
  max-width: 100%;
  padding: 0;
  font-size: 16px;
  font-family: "Rooster", "Arial", sans-serif;
}

#inscription span.select2.select2-container--open .selection .select2-selection,
#inscription span.select2.select2-container--focus .selection .select2-selection {
  border-color: #777;
}

#inscription span.select2 .selection .select2-selection {
  box-sizing: border-box;
  height: 51px;
  border: 1px solid #c8c8c8;
  background: #f9f9f9;
}

#inscription span.select2 .selection .select2-selection .select2-selection__rendered {
  height: 49px;
  padding: 15px 40px 15px 10px;
  line-height: 18px;
}

#inscription span.select2 .selection .select2-selection .select2-selection__arrow {
  width: 40px;
  height: calc(100% - 2px);
}

#inscription select.error+span.select2 .selection .select2-selection {
  border-left: 2px solid #c20016;
}

#inscription .warning {
  font-size: 16px;
  line-height: 22px;
  color: #000;
}

#inscription .warning span {
  color: #d22630;
}

#inscription div#warehouses {
  display: none;
  position: absolute;
  border: 1px solid #c8c8c8;
  border-top: 0;
  background: #f9f9f9;
  top: calc(100%);
  padding: 15px 0;
  width: 100%;
  z-index: 999;
  max-height: 200px;
  overflow: auto;
}

#inscription div#warehouses p {
  margin-bottom: 0;
  line-height: 24px;
}

#inscription div#warehouses p span {
  color: #707070;
  display: block;
  padding: 0 15px;
}

#inscription div#warehouses p span:not(.noresult):hover {
  background: #e0e0e0;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  #inscription .field {
    max-width: 100%;
    flex: 1 0 100%;
  }
}

.select2-container .select2-dropdown {
  padding: 10px;
  border: 1px solid #777;
  border-top: none;
  border-radius: 0;
}

.select2-container .select2-dropdown .select2-search {
  margin: 0 0 10px 0;
  padding: 0;
}

.select2-container .select2-dropdown .select2-search .select2-search__field {
  overflow: hidden;
  box-sizing: border-box;
  max-width: 100%;
  padding: 8px 0;
  font-size: 14px;
  font-family: "Rooster", "Arial", sans-serif;
  text-indent: 10px;
  border: 1px solid #c8c8c8;
  background: #f9f9f9;
}

.select2-container .select2-dropdown .select2-search .select2-search__field:active,
.select2-container .select2-dropdown .select2-search .select2-search__field:focus {
  border-color: #777;
}

html {
  height: 100%;
  min-height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto;
  font-size: 14px;
  font-family: "Rooster", "Arial", sans-serif;
  line-height: normal;
  color: #000000;
  outline: none;
}

body main {
  flex-grow: 1;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
  font-weight: normal;
}

img {
  display: block;
}

iframe {
  border: none;
}

a {
  text-decoration: none;
  color: #d22630;
}

a:focus,
input:focus,
textarea:focus,
select:focus,
button:focus {
  border-radius: 0;
  outline: none;
}

input {
  border-radius: 0;
}

sup {
  font-size: 0.4em;
  line-height: 0;
  vertical-align: super;
}

::selection {
  color: white;
  background: #d22630;
}

::-moz-selection {
  color: white;
  background: #d22630;
}

.color {
  color: #d22630;
}

.center {
  text-align: center;
}

.wrap {
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
}

#inscription input[type="checkbox"] {
  overflow: initial;
}


@media only screen and (max-width: 1023px) {
  .wrap {
    max-width: 94%;
  }

  #inscription input[type="checkbox"] {
    overflow: initial;
  }
}